<script setup lang="ts">
import { getDeviceVideoUrl } from '@/utils/video'

const props = defineProps(['visible', 'videoPath', 'deviceName', 'videoPlayerStatus'])
const emits = defineEmits(['onClose'])

const videoUrl = ref('')
// const videoUrl = ref('http://localhost:3001/backend-resource/平台事件（实时）/platformEvent_15.mp4?t=1722309744005')
const seekable = ref<boolean>(true)

watch(() => props.visible, (visible) => {
  if (visible) {
    play()
  }
})

function play() {
  videoUrl.value = ''
  const proxied = true
  videoUrl.value = getDeviceVideoUrl(props.videoPath, proxied ?? false) as string
  seekable.value = true
}

function onClose() {
  emits('onClose')
}
</script>

<template>
  <a-modal :open="visible" :title="deviceName ?? ''" centered :width="599" :footer="null" @cancel="onClose">
    <div class="video">
      <video-player :video-player-status="videoPlayerStatus" :has-audio="false" :video-url="videoUrl" :visible="visible" :seekable="seekable" />
    </div>
  </a-modal>
</template>

<style lang="scss" scoped>
.video {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    height: 500px;
    width: 550px;
}
</style>
